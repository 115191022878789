<template>
  <div>
    <Loader :loading="loading" />
    <v-row>
      <v-col cols="12" md="8" sm="12">
        <v-form ref="form" onsubmit="return false;">
          <v-row>
            <v-col cols="12" md="6" sm="6" class="pb-0">
              <v-text-field
                :value="order.order_num"
                label="Pedido #"
                dense
                outlined
                disabled
              />
              <v-textarea
                v-model="order.instructions"
                label="Instrucciones"
                dense
                outlined
                rows="2"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" class="pb-0">
              <SelectEntities
                ref="selectEntities"
                v-model="contactDestiny"
                label="Proveedor"
                outlined
                :rules="[isRequiredObj]"
                include="contactable.supplier"
                type-entity="SUPPLIER"
                with-details
              />
            </v-col>
          </v-row>
        </v-form>
        <Items
          :order-items="items"
          @onRemoveItem="onRemoveItem"
        />
        <div class="d-flex justify-end">
          <h4>Total: <b class="success--text">Bs. {{ total }}</b></h4>
        </div>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <div class="d-flex justify-end mb-1">
          <v-btn
            color="warning"
            small
            tile
            elevation="0"
            @click="updateOrder('PENDING')"
            :disabled="!validateItems"
          >
            actualizar
          </v-btn>
          <v-btn
            color="primary"
            small
            tile
            elevation="0"
            class="ml-1"
            @click="updateOrder('FAST_BUY')"
            :disabled="!validateItems"
          >
            Recibir
          </v-btn>
        </div>
        <InventoryBySupplier
          ref="inventories"
          @onSendSku="onGetSku"
          :supplier="currentSupplier"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// components
import Items from '../order/OrderItemsBuy';
import StatusServices from '@/store/services/general/StatusServices';
// services
import OrderServices from '@/store/services/warehouse/OrderServices';
import InventoryBySupplier from '@/views/warehouse/order/InventoryBySupplier';
import SelectEntities from '@/components/commons/SelectEntities';
// helpers
import { mapGetters } from 'vuex';
import { objectEmpty } from '@/constants/helpers';
export default {
  components: {
    InventoryBySupplier,
    Items,
    SelectEntities,
  },
  data() {
    return {
      loading: false,

      items: [],
      warehouses: [],
      statuses: [],

      order: {
        user_id: '',
        origin_warehouse_id: null,
        destiny_warehouse_id: null,
        buy_contact_id: null,
        bill_contact_id: null,
        order_num: this.getNumPO(),
        instructions: '',
        total: 0,
        delivery_date: new Date(),
        type: '',
        status_id: '',
      },

      contactDestiny: {},

      //rules
      isRequired: value => !!value || 'Campo Requerido',
      isRequiredObj: value => !objectEmpty(value) || 'Campo requerido',
    };
  },
  created() {
    this.getStatuses();
    this.getOrder();
  },
  methods: {
    async getOrder() {
      try {
        const params = {
          include: 'items.sku.product,destiny,status,buyContact.contactable.supplier',
        };
        this.loadingShow = true;
        const response = await OrderServices.show(this.user.archon.id, this.orderId, params);
        this.order = response.data;
        this.items = response.data.items.map(item => ({
          sku_id: item.sku.id,
          sku: item.sku.sku,
          product: item.sku.product.name,
          sold_price: item.sold_price,
          quantity: item.quantity,
          qty_available: 0,
          discount: item.discount,
        }));
        this.$refs['selectEntities'].selected = response.data.buy_contact;
        this.loadingShow = false;
      } catch (error) {
        this.loadingShow = false;
        this.$notify({
          group: 'petition',
          type: 'error',
          title: 'Pedido',
          text: `Pedido ${this.order.order_num} no se pudo recuperar`,
        });
      }
    },
    async getStatuses() {
      try {
        const response = await StatusServices.index(this.user.archon.id);
        this.statuses = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    getStatus(name) {
      const index = this.statuses.findIndex(status => status.name === name);
      return this.statuses[index];
    },
    getNumPO() {
      const n = Math.round(Math.random()*999999 + 9999999);
      return `B-${n}`;
    },
    onGetSku(data) {
      const index = this.items.findIndex(item => item.sku_id === data.sku.id);
      if (index === -1) {
        const newItem = {
          sku_id: data.sku.id,
          sku: data.sku.sku,
          product: data.sku.product.name,
          sold_price: 0,
          quantity: data.quantity,
          qty_available: data.qty_available,
          discount: 0,
        };
        this.items.unshift(newItem);
      } else {
        const oldQuantity = parseInt(this.items[index].quantity);
        this.items[index].quantity = oldQuantity + parseInt(data.quantity);
      }
    },
    onRemoveItem(data) {
      const index = this.items.findIndex(item => item.sku_id === data.sku.sku_id);
      this.items.splice(index, 1);
    },
    async updateOrder(statusOrder) {
      if (this.$refs.form.validate()) {
        if (this.items.length > 0) {
          try {
            const status = this.getStatus(statusOrder);
            this.order.status_id = status.id;
            this.order.user_id = this.user.id;
            this.order.destiny_warehouse_id = this.user.warehouse.id;
            this.order.type = 'BUY';
            this.order.buy_contact_id = this.contactDestiny.id;
            this.order.bill_contact_id = this.contactDestiny.id;
            this.order.total = this.total;
            this.order['items'] = this.items.map(item => ({
              sku_id: item.sku_id,
              quantity: item.quantity,
              sold_price: item.sold_price,
            }));
            this.order['remove_items'] = true;
            this.loading = true;
            await OrderServices.update(this.user.archon.id, this.orderId, this.order);
            this.$router.push({
              name: 'buyList',
            });
            this.loading = false;
          } catch (error) {
            this.loading = false;
            this.$notify({
              group: 'petition',
              type: 'error',
              title: 'Pedido',
              text: `Pedido ${this.order.order_num} no se pudo guardar`,
            });
          }
        } else this.$notify({
          group: 'petition',
          type: 'warning',
          title: 'Skus',
          text: `El pedido no tiene skus`,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    validateItems() {
      return this.items.filter(sku => parseFloat(sku.discount) > (parseInt(sku.quantity) * parseFloat(sku.sold_price))).length === 0;
    },
    total() {
      let total = 0;
      this.items.forEach(item => {
        total += parseFloat(item.sold_price * item.quantity) - parseFloat(item.discount);
      });
      return total.toFixed(2);
    },
    currentSupplier() {
      return this.contactDestiny?.contactable?.supplier ?? {};
    },
    orderId() {
      return this.$route.params.orderId;
    },
  },
}
</script>