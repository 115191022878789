<template>
  <div style="width: 100%;">
    <v-autocomplete
      v-model="selected"
      :items="entities"
      :loading="loading"
      :search-input.sync="search"
      :rules="rules"
      item-text="name"
      item-value="id"
      :label="label"
      placeholder="Buscar"
      return-object
      dense
      attach
      :filter="customFilter"
    >
      <template v-slot:selection="data">
        <v-list-item-content v-bind="data.attrs">
          <v-list-item-subtitle>
            <div>
              <v-icon left small>mdi-account-outline</v-icon>
              <small> {{ data.item.name }} </small>
            </div>
            <div>
              <v-icon left small>mdi-card-account-details-outline</v-icon>
              <small> {{ data.item.nit }} </small>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>

      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-subtitle>
            <div>
              <v-icon left small>mdi-account-outline</v-icon>
              <small> {{ data.item.name }} </small>
            </div>
            <div>
              <v-icon left small>mdi-card-account-details-outline</v-icon>
              <small> {{ data.item.nit }} </small>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
// import ContactServices from '@/store/services/general/ContactServices';
import EntityServices from '@/store/services/general/EntityServices'
import { mapGetters } from 'vuex';
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    include: {
      type: String,
      default: '',
    },
    typeEntity: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    entity: {
      default: null
    },
    withDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: {},
      search: '',

      loading: false,
      entities: [],
    };
  },
  mounted() {
    this.getEntities();
  },
  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const last_name = item.last_name.toLowerCase();
      const ic = item.ic.toLowerCase();
      const email1 = item.email1.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        name.indexOf(searchText) > -1 || 
        last_name.indexOf(searchText) > -1 ||
        ic.indexOf(searchText) > -1 ||
        email1.indexOf(searchText) > -1
      );
    },
    async getEntities() {
      try {
        this.loading = true;
        const params = {
          order_by: 'name',
          order_direction: 'ASC',
          contactable_type: 'entity',
          
          include: this.include,
          // search_all: this.search,
          search_type: this.typeEntity,
          // search: this.typeEntity,
          // per_page: 20,
          // page: 1,
        };
        const response = await EntityServices.index(
          this.user.archon.id,
          params
        );
        // const response = await ContactServices.index(
        //   this.user.archon.id,
        //   params
        // );
        this.entities = response.data.data;
        // if (response.data.data.length > 0) {
        //   this.search = '';
        // } 
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    createEntity() {
      this.$refs['createEntity'].dialog = true;
    },
    setEntities() {
      if (this.entity) {
        console.log(this.entity[0])
        this.selected = this.entity[0]
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  watch: {
    selected() {
      this.$emit('input', this.selected);
    },
    entity: {
      deep: true,
      immediate: true,
      handler: 'setEntities'
    }
  },
};
</script>